import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import bgTranslation from "app/locales/bg/translation.json";
import enTranslation from "app/locales/en/translation.json";
import ruTranslation from "app/locales/ru/translation.json";
import zhTranslation from "app/locales/zh/translation.json";
import bgLanding from "app/locales/bg/landing.json";
import enLanding from "app/locales/en/landing.json";
import ruLanding from "app/locales/ru/landing.json";
import zhLanding from "app/locales/zh/landing.json";
import bgZod from "zod-i18n-map/locales/bg/zod.json";
import enZod from "zod-i18n-map/locales/en/zod.json";
import ruZod from "zod-i18n-map/locales/ru/zod.json";
import zhZod from "zod-i18n-map/locales/zh-CN/zod.json";
import {z} from "zod";
import {customZodI18nMap} from "app/utils/zodErrorMap";

export const defaultNS = "translation" as const;

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const resources = {
  bg: {
    translation: bgTranslation,
    landing: bgLanding,
    zod: bgZod
  },
  en: {
    translation: enTranslation,
    landing: enLanding,
    zod: enZod
  },
  ru: {
    translation: ruTranslation,
    landing: ruLanding,
    zod: ruZod
  },
  zh: {
    translation: zhTranslation,
    landing: zhLanding,
    zod: zhZod
  }
} as const;

export const supportedLanguages = [
  {name: "Български", code: "bg-BG", flag: "🇧🇬"},
  {name: "English", code: "en-GB", flag: "🇬🇧"},
  {name: "Руский", code: "ru-RU", flag: "🇷🇺"},
  {name: "中文", code: "zh-CN", flag: "🇨🇳"}
] as const;

export const initI18n = async () => {
  const search = new URLSearchParams(window.location.search);
  const langParam = search.get("lang");
  const supportedLanguage = supportedLanguages.find(({code}) => code === langParam) ?? supportedLanguages[0];

  let currentLanguage = supportedLanguage.code;
  await i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      defaultNS,
      ns: defaultNS,
      debug: import.meta.env.DEV,
      resources,
      lng: currentLanguage, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option
      // supportedLngs: ["bg", "en"],
      interpolation: {
        escapeValue: false // react already safes from xss
      }
      // react: {
      //   bindI18n: "loaded languageChanged",
      //   bindI18nStore: "add",
      //   useSuspense: false
      // }
    });
  z.setErrorMap(customZodI18nMap);
  i18n.on("languageChanged", (lng) => {
    document.documentElement.lang = lng;
  });
  document.documentElement.lang = currentLanguage;
  return i18n;
};
